import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { ProductSearchResultProduct } from '@/models/Product';
import { VariantModel } from '@/models/Variant';
import ProductService from '@/services/ProductService';
import VariantService from '@/services/VariantService';
import StockReminderService from '@/services/StockReminderService';

@Module({
    store,
    dynamic: true,
    name: 'productdetails',
    namespaced: true,
})
class ProductDetailsModule extends VuexModule {

    private svc: ProductService = new ProductService();
    private variantService: VariantService = new VariantService();
    private stockReminderService: StockReminderService = new StockReminderService();

    private product: ProductSearchResultProduct = null;
    private isLoading: boolean = false;
    private variants: VariantModel[] = null;
    private selectedVariant: VariantModel = null;
    private selectedVariantId: string = null;
    private selectedDimension1: string = null;

    get PRODUCT(): ProductSearchResultProduct {
        return this.product;
    }
    get IS_LOADING(): boolean {
        return this.isLoading;
    }
    get VARIANTS(): VariantModel[] {
        return this.variants;
    }
    get SELECTED_VARIANT(): VariantModel {
        return this.selectedVariant;
    }
    get SELECTED_VARIANT_ID(): string {
        return this.selectedVariantId;
    }    
    get SELECTED_DIMENSION_1(): string {
        return this.selectedDimension1;
    }

    @Action({ commit: 'SET_PRODUCT' })
    public async GET_PRODUCT(id: string) {
        this.SET_LOADING(true);
        const result = await this.svc.getProductsById([id]);
        return result[0];
    }

    @Action({ commit: 'SET_VARIANTS' })
    public async GET_VARIANTS(id: string) {
        this.SET_LOADING(true);
        const result = await this.variantService.getVariants(id);
        return result;
    }

    @Action
    public async SendStockReminder(email: string) {
        const productId = this.selectedVariantId ?? this.product.id;
        return await this.stockReminderService.add({email, productId})
    }

    @Mutation
    public SET_PRODUCT(newproduct: ProductSearchResultProduct) {
        this.product = newproduct;
        this.isLoading = false;
    }

    @Mutation
    public SET_VARIANTS(newvariants: VariantModel[]) {
        this.variants = newvariants;
        this.isLoading = false;
    }
    
    @Mutation
    public SET_SELECTED_VARIANT(variant: VariantModel) {
        this.selectedVariant = variant;
    }
    @Mutation
    public SET_SELECTED_VARIANT_ID(id: string) {
        this.selectedVariantId = id;
    }
    @Mutation
    public SET_SELECTED_DIMENSION_1(dimensionCode: string) {
        this.selectedDimension1 = dimensionCode;
    }
    
    @Mutation
    public SET_LOADING(args: boolean) {
        this.isLoading = args;
    }
}

export default getModule(ProductDetailsModule);